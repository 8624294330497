import React from 'react'
import { graphql } from 'gatsby'
import { Parallax, Background } from 'react-parallax';
import Layout from '../components/layout'
import Hero from '../components/hero'
import ArticlePreview from '../components/article-preview'
import GroupSection from '../components/group.section'
import CarouselSection from '../components/carousel.section'
import Concerts from '../components/concert.section'
import ContactUs from '../components/contactUs'
import Videos from '../components/videos'
import { renderRichText } from 'gatsby-source-contentful/rich-text'

const RootIndex = (props) => {
    const posts = props.data.allContentfulBlogPost.nodes
    const [author] = props.data.allContentfulGroupInfo.nodes
    const musiciens = props.data.allContentfulMusicien.nodes
    const concerts = props.data.allContentfulConcert.nodes
    const videos = props.data.allContentfulVideo.nodes
    const galerie = props.data.allContentfulGalerie.nodes[0]

    return (
      <Layout location={props.location}>
         <Parallax strength={2000} style={{width: '100%'}}>
          <Background style={{width: '100%'}}>
            <Hero
              image={author.heroImage}
              title={author.name}
              content={author.title}
            />
          </Background>
          <div style={{marginTop: '70vh', background: 'white'}}>
            <div>
            <div style={{height: '70px', textAlign: 'center'}}>
              <h2>L'HISTOIRE</h2>
            </div>
            <div style={{textAlign: 'center', maxWidth: '900px', padding: 10, margin: 'auto'}}>
              {author.shortBio?.raw && renderRichText(author.shortBio)}
            </div>
            </div>
            <Videos videos={videos} />
            <GroupSection musiciens={musiciens} />
            <CarouselSection carousel={galerie.carousel} />
            <ArticlePreview posts={posts} />
            <Concerts concerts={concerts} />
          </div>
          <ContactUs author={author} />
        </Parallax>
      </Layout>
    )
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      nodes {
        title
        slug
        publishDate(formatString: "MMMM Do, YYYY")
        tags
        heroImage {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            width: 424
            height: 212
          )
        }
        description {
          raw
        }
      }
    }
    allContentfulGroupInfo(
      filter: { contentful_id: { eq: "15jwOBqpxqSAOy2eOO4S0m" } }
    ) {
      nodes {
        name
        shortBio {
          raw
        }
        title
        heroImage: image {
          file {
            url
          }
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: BLURRED
            width: 1180
          )
        }
        facebook
        github
      }
    }
    allContentfulMusicien {
      nodes {
        name
        shortBio {
          raw
        }
        heroImage: image {
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: BLURRED
            width: 1180
          )
        }
        backgroundColor
      }
    }
    allContentfulConcert {
      nodes {
        name
        place
        date(formatString: "DD/MM/YYYY")
        link
      }
    }
    allContentfulVideo {
      nodes {
        title
        youtubeId
      }
    }
    allContentfulGalerie {
      nodes {
        carousel {
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: BLURRED
            width: 1180
          )
        }
      }
    }
  }
`
