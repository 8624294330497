import React from 'react'

const style = {
  width: "100%",
  padding: 10,
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr)",
  gridGap: 10,
  gridAutoRows: "minmax(100px, auto)"
} 

const Videos = ({ videos }) => {
  console.log(videos)
  return <div>
    <div style={{height: '70px', textAlign: 'center'}}>
      <h2>NOS VIDEOS</h2>
    </div>
    <div style={style}>
      { videos.map((video, i) => {
        const id = video.youtubeId.replace("https://www.youtube.com/watch?v=", "")
        return <div key={i}>
          <h3>{video.title}</h3>
          <iframe width="560" 
                  height="315" 
                  style={{maxWidth: "95vw"}}
                  src={`https://www.youtube.com/embed/${id}`} 
                  title="YouTube video player" 
                  frameBorder="0" 
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                  allowFullScreen></iframe>
        </div>
      })}
    </div>
  </div>
}

export default Videos;