import React from 'react'
import { BsCalendar3 } from 'react-icons/bs';
import { FaMapMarkerAlt } from 'react-icons/fa';

const Concerts = ({ concerts }) => {
  const redirect = (link) => {
    window.location.href=link
  }

  const pastConcerts = concerts.filter((c) => {
    if (!c.date) return false
    const splited = c.date.split('/')
    return new Date(`${splited[1]}/${splited[0]}/${splited[2]}`).getTime() < new Date().getTime()
  }).sort((a, b) => {
    const splitedA = a.date.split('/')
    const splitedB = b.date.split('/')
    return new Date(`${splitedB[1]}/${splitedB[0]}/${splitedB[2]}`).getTime() - new Date(`${splitedA[1]}/${splitedA[0]}/${splitedA[2]}`).getTime()
  } )
  const futuresConcerts = concerts.filter((c) => {
    if (!c.date) return false
    const splited = c.date.split('/')
    return new Date(`${splited[1]}/${splited[0]}/${splited[2]}`).getTime() >= new Date().getTime()
  }).sort((a, b) => {
    const splitedA = a.date.split('/')
    const splitedB = b.date.split('/')
    return new Date(`${splitedB[1]}/${splitedB[0]}/${splitedB[2]}`).getTime() - new Date(`${splitedA[1]}/${splitedA[0]}/${splitedA[2]}`).getTime()
  } )
  return <div>
    <div style={{height: '70px', textAlign: 'center'}}>
      <h2>CONCERTS À VENIR</h2>
    </div>
    { futuresConcerts.map((concert, i) => 
      <div key={i} style={{ display: 'flex', 
                    padding: 10,
                    gap: 30, 
                    maxWidth: 800, 
                    background: "#e9c46a",
                    borderBottom: '1px solid',
                    margin: 'auto' }}>
          <p>{concert.name}</p>
          <p><FaMapMarkerAlt /> {concert.place}</p>
          <p><BsCalendar3 /> {concert.date}</p>
          <div style={{flex: 1, display: 'flex', justifyContent: 'end'}}>
            { concert.link && <button className='styledButton' onClick={() => redirect(concert.link)}>Plus d'info</button>}
          </div>
      </div>
    )}
    <div style={{height: '70px', textAlign: 'center'}}>
      <h2>CONCERTS PASSÉS</h2>
    </div>
    { pastConcerts.map((concert, i) => 
      <div key={i} style={{ display: 'flex', 
                    padding: 10,
                    gap: 30, 
                    maxWidth: 800, 
                    background: "#264653",
                    borderBottom: '1px solid',
                    margin: 'auto' }}>
          <p>{concert.name}</p>
          <p><FaMapMarkerAlt /> {concert.place}</p>
          <p><BsCalendar3 /> {concert.date}</p>
          <div style={{flex: 1, display: 'flex', justifyContent: 'end'}}>
            { concert.link && <button className='styledButton' onClick={() => redirect(concert.link)}>Plus d'info</button>}
          </div>
      </div>
    )}
  </div>
}
export default Concerts