import React, { Component } from "react";
import { BsFacebook, BsInstagram } from 'react-icons/bs';
import * as styles from '../components/contact.module.css'

const Contact = ({author}) => {
  return (
    <div className="contact section" id="Contact" style={{minHeight: '97vh' }}>
      <div className="container">
        <div className="section-head" style={{textAlign: 'center'}}>
          <h2 className="text-center">CONTACT</h2>
        </div>
        <form
          style={{maxWidth: 800, margin: 'auto'}}
          action={`https://formspree.io/f/xyyorgqj`}
          name="contact"
          method="POST"
          data-netlify="true"
        >
          <div>
            <label>
              Nom: <input type="text" name="nom" required />
            </label>
          </div>
          <div>
            <label>
              Email: <input type="email" name="email" required />
            </label>
          </div>
          <div>
            <label>
              Message: <textarea name="message" required></textarea>
            </label>
          </div>
          <div>
            <button type="submit">Envoyer</button>
          </div>
        </form>
        <div className={styles.socialContainer}>
          <BsFacebook onClick={() => window.location.href = author.facebook} />
          <BsInstagram onClick={() => window.location.href = author.github}/>
        </div>
      </div>
    </div>
  );
}

export default Contact